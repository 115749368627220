




































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import { getWorkflow, deleteWorkflow, updateWorkflow, getWorkflowQueue, abortWorkflowQueue } from '@/api/consoleApi/workflows'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { get } from 'lodash'
import EditNameModal from '@/modules/workflows/components/EditNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'EditWorkflow',
    components: {
        VsSectionHeader,
        VsContainer,
        VsTabsHeading,
        VsDropdownButton,
        VsFilterContainer,
        EditNameModal,
        VsConfirm,
        VsLoader,
        VsTable,
    },
})
export default class extends Vue {
    loading = false
    workflow: Workflow | null = null
    workflowQueue: any[] = []
    activeTab = 'workflowQueue'
    total = 0
    filters = {
        search: '',
        searchContact: '',
        status: '',
    }

    pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    $refs!: any

    get isMdViewport () {
        return AppModule.isMdViewport
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get countFilters () {
        let count = 0
        if (this.filters.status && this.filters.status.length > 0) count++
        if (this.filters.search && this.filters.search.length > 0) count++
        if (this.filters.searchContact && this.filters.searchContact.length > 0) count++
        return count
    }

    get orderByOptions () {
        return [
            {
                label: 'Più recenti',
                value: 'id|desc',
            },
            {
                label: 'Meno recenti',
                value: 'id|asc',
            },
            {
                label: 'Data di invio decrescente',
                value: 'send_date|desc',
            },
            {
                label: 'Data di invio crescente',
                value: 'send_date|asc',
            },
        ]
    }

    get messages () {
        return this.workflow?.messages?.data || []
    }

    get tableHeaders (): TableHeader[] {
        return [
            {
                id: 'sent_to',
                key: 'sent_to',
                label: 'Contatto',
                formatter: (value: any) => value || 'non trovato',
                visible: true,
            },
            {
                id: 'workflow_message.data.name',
                key: 'workflow_message.data.name',
                label: 'Messaggio',
                visible: true,
            },
            {
                id: 'created_at',
                key: 'created_at',
                label: 'Data di registrazione',
                formatter: (value: any) => this.$options?.filters?.formatDate(value),
                visible: true,
            },
            {
                id: 'send_date',
                key: 'send_date',
                label: 'Data di invio',
                formatter: (value: any) => this.$options?.filters?.formatDate(value),
                visible: true,
            },
            {
                id: 'status',
                key: 'status',
                label: 'Status',
                formatter: (value: any) => this.$t('workflows.queueStatus.' + value),
                visible: true,
            },
        ]
    }

    get tabs () {
        const tabs = [
            {
                label: 'Riepilogo',
                id: 'editWorkflow',
                to: {
                    name: 'editWorkflow',
                },
            },
            {
                label: 'Statistiche',
                id: 'workflowStatistics',
                iconName: 'external-link-minor',
                url: `/app/user/reports/automation/dashboard/${this.workflowId}`,
            },
            {
                label: 'Coda di invio',
                id: 'workflowQueue',
                to: {
                    name: 'workflowQueue',
                },
            },
        ]

        return tabs
    }

    get workflowId () {
        return this.$route?.params?.workflowId || ''
    }

    get params () {
        const params = {
            orderBy: 'id',
            sortedBy: 'desc',
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
            page: this.pagination.page,
            include: 'subscriber,workflow_message',
            limit: this.pagination.itemsPerPage,
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }
        return params
    }

    private buildSearch () {
        const search = [
            this.filters.status ? `status:${this.filters.status}` : '',
            this.filters.searchContact.trim() !== '' ? `sent_to:${this.filters.searchContact}` : '',
            this.filters.search.trim() !== '' ? `workflow_message.name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.status ? 'status:=' : '',
            this.filters.searchContact.trim() !== '' ? 'sent_to:like' : '',
            this.filters.search.trim() !== '' ? 'workflow_message.name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private showWarning (workflowQueue: any) {
        const contact = this.getContactCurrentInfo(workflowQueue)
        return workflowQueue.status === 'ready' &&
            workflowQueue.sent_to &&
            workflowQueue.subscriber &&
            workflowQueue.sent_to !== contact
    }

    private getContactCurrentInfo (workflowQueue: any) {
        return workflowQueue.entity_type === 'sms' ? get(workflowQueue, 'subscriber.data.mobile', '') : get(workflowQueue, 'subscriber.data.email_address', '')
    }

    beforeMount () {
        this.getWorkflow()
        this.getWorkflowQueue()
    }

    async getWorkflow () {
        try {
            const resp = await getWorkflow(this.workflowId)
            this.workflow = resp.data.data
        } catch (e) {
            const code = get(e, 'response.status', null)
            if (code === 404) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: 'Marketing automation non trovata',
                    aspect: VsToastAspectEnum.alert,
                })
                this.$router.replace({ name: 'workflows' })
                return
            }
            console.log(e)
        }
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        if (this.pagination.page === 1) {
            this.getWorkflowQueue()
        } else {
            this.pagination.page = 1
        }
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getWorkflowQueue () {
        this.loading = true
        try {
            const resp = await getWorkflowQueue(this.workflowId, this.params)
            this.workflowQueue = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.workflowQueue = []
            this.total = 0
        }
        this.loading = false
    }

    get badgeStatusAspect () {
        switch (this.workflow?.status || null) {
            case 'ready':
                return 'success'
            case 'pause':
                return 'warning'
            case 'draft':
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.workflow?.status) return ''
        switch (this.workflow?.status || null) {
            case 'pause':
                return 'pause'
            case 'ready':
                return 'double-check'
            case 'draft':
                return 'edit'
        }
    }

    async editAutomationName (event: any) {
        this.loading = true
        try {
            await updateWorkflow(this.workflow?.id || '', {
                name: event.name,
            })
            this.getWorkflow()
            this.$root.$vsToast({
                heading: 'Nome modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del nome',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteWorkflow () {
        if (!this.workflow) return ''
        try {
            await this.$refs.confirmDelete.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true
        try {
            await deleteWorkflow(this.workflowId)
            this.$root.$vsToast({
                heading: 'Flusso eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'workflows',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione del flusso',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async abortWorkflowQueue (workflowQueueId: any) {
        try {
            await this.$refs.confirmAbort.openConfirm()
        } catch (e) {
            return
        }
        try {
            await abortWorkflowQueue(this.workflowId, workflowQueueId)
            this.getWorkflowQueue()
            this.$root.$vsToast({
                heading: 'Messaggio cancellato dalla coda con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dalla coda',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }
}
