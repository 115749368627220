var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',[(_vm.workflow)?_c('div',[(_vm.workflow)?_c('VsTabsHeading',{attrs:{"heading":_vm.workflow.name,"backTo":{
                name: 'workflows'
            },"backTip":"Torna all'elenco Marketing automation","tabs":_vm.tabs,"activeTab":_vm.activeTab},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{staticClass:"vs-flex -vs-items-center vs-gap-2"},[(_vm.workflow.status)?_c('VsBadge',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"aspect":_vm.badgeStatusAspect,"variant":"icon-left","title":_vm.$t('workflows.workflowCard.status.' + _vm.workflow.status),"iconName":_vm.badgeStatusIcon}},[_c('span',{staticClass:"vs-hidden md:vs-inline-flex"},[_vm._v(" "+_vm._s(_vm.$t('workflows.workflowCard.status.' + _vm.workflow.status))+" ")])]):_vm._e()],1)]},proxy:true}],null,false,2433473111)},[_c('VsActionItem',{attrs:{"label":"Modifica nome automation"},on:{"click":function($event){return _vm.$refs.editNameModal.openModal(_vm.workflow.name)}}}),_c('VsActionItem',{attrs:{"aspect":"alert","label":'Elimina automation'},on:{"click":_vm.deleteWorkflow}})],1):_vm._e(),_c('VsSectionHeader',{staticClass:"vs-mb-8",attrs:{"truncate":"","size":"medium","heading":"Coda di invio"}}),_c('div',{staticClass:"vs-flex md:vs-flex-nowrap vs-my-8 vs-justify-between vs-flex-wrap vs-gap-4"},[_c('div',{staticClass:"vs-flex vs-items-center vs-gap-4"},[_c('VsFilterContainer',{attrs:{"countFilters":_vm.countFilters,"totalFound":_vm.$tc('general.found', _vm.total)},on:{"clear":function($event){_vm.filters = {
                        search: '',
                        searchContact: '',
                        status: '',
                    }}}},[_c('VsSelect',{attrs:{"clearable":"","labelHidden":"","placeholder":"Status","options":[
                            {
                                label: _vm.$t('workflows.queueStatus.ready'),
                                value: 'ready',
                            },
                            {
                                label: _vm.$t('workflows.queueStatus.sent'),
                                value: 'sent',
                            },
                            {
                                label: _vm.$t('workflows.queueStatus.failed'),
                                value: 'failed',
                            },
                            {
                                label: _vm.$t('workflows.queueStatus.cancel'),
                                value: 'cancel',
                            },
                            {
                                label: _vm.$t('workflows.queueStatus.process'),
                                value: 'process',
                            } ]},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('VsInput',{attrs:{"clearable":"","iconName":"search","labelHidden":"","placeholder":"Cerca contatto...","debounce":"500"},model:{value:(_vm.filters.searchContact),callback:function ($$v) {_vm.$set(_vm.filters, "searchContact", $$v)},expression:"filters.searchContact"}}),_c('VsInput',{attrs:{"clearable":"","iconName":"search","labelHidden":"","placeholder":"Cerca messaggio...","debounce":"500"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),(_vm.countFilters > 0)?_c('VsButton',{staticClass:"md:vs-hidden",attrs:{"variant":"link"},on:{"click":function($event){_vm.filters = {
                        search: '',
                        searchContact: '',
                        status: '',
                    }}}},[_vm._v(" Svuota filtri ")]):_vm._e(),_c('div',{staticClass:"vs-body-medium vs-flex-none"},[_vm._v(_vm._s(_vm.$tc('general.found', _vm.total)))])],1),_c('div',{staticClass:"vs-flex-initial vs-flex vs-items-center vs-gap-4"},[_c('VsSelect',{staticStyle:{"max-width":"134px"},attrs:{"placeholder":_vm.$t('lists.index.orderBy.createdAtDesc'),"labelHidden":true,"clearable":true,"size":(_vm.isMdViewport || _vm.isSmViewport) ? 'small' : 'medium',"options":_vm.orderByOptions},model:{value:(_vm.pagination.orderBy),callback:function ($$v) {_vm.$set(_vm.pagination, "orderBy", $$v)},expression:"pagination.orderBy"}})],1)]),_c('VsTable',{staticClass:"vs-relative",attrs:{"actionColumn":"","fixedAction":"","selectTarget":"id","loading":_vm.loading,"items":_vm.workflowQueue,"headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"col-sent_to",fn:function(ref){
                    var row = ref.row;
return [(row.sent_to)?_c('div',{staticClass:"vs-flex vs-gap-1 vs-items-center"},[_c('VsButton',{attrs:{"to":{
                            name: 'contactDetails',
                            params: {
                                listId: row.list_id,
                                contactId: row.subscriber_id,
                            }
                        },"target":"_blank","variant":"link"}},[_vm._v(" "+_vm._s(row.sent_to)+" ")]),(_vm.showWarning(row))?_c('VsIcon',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"color":"warning-500","name":"alert","content":'Il recapito del contatto è stato modificato e verrà utilizzato quello attuale al momento dell\'invio: ' + _vm.getContactCurrentInfo(row)}}):_vm._e()],1):_c('span',[_vm._v(" Non trovato ")])]}},{key:"headActions",fn:function(){return [_c('div',{staticClass:"vs-text-right vs-py-4 vs-px-3 vs-h300"},[_vm._v(" Azioni ")])]},proxy:true},{key:"bodyActions",fn:function(ref){
                        var row = ref.row;
return [_c('div',{staticClass:"vs-px-3 vs-text-right"},[(row.status === 'ready')?_c('VsButton',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-p-0 vs-text-alert-900",staticStyle:{"padding":"0!important"},attrs:{"title":"Cancella","size":"large","aspect":"ghost","variant":"icon","iconName":"trash"},on:{"click":function($event){return _vm.abortWorkflowQueue(row.id)}}}):_vm._e()],1)]}}],null,false,2269780577)}),_c('VsPagination',{staticClass:"vs-mt-8",attrs:{"totalItems":_vm.total,"filterable":"","expand":"","currentPage":_vm.pagination.page,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e(),_c('EditNameModal',{ref:"editNameModal",on:{"updated":_vm.editAutomationName}}),_c('VsConfirm',{ref:"confirmDelete",attrs:{"closeButton":_vm.$t('general.cancel'),"confirmButton":"Elimina"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Una volta eliminato non sarà più possibile recuperare i dati ed eventuali messaggi in coda verranno cancellati. ")]},proxy:true}])},[_vm._v(" Sicuro di voler eliminare l'intero flusso? ")]),_c('VsConfirm',{ref:"confirmAbort",attrs:{"closeButton":_vm.$t('general.cancel'),"confirmButton":"Cancella"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Una volta messo in status Cancellato non verrà più inviato il messaggio a questo contatto. ")]},proxy:true}])},[_vm._v(" Sicuro di voler cancellare questo messaggio dalla coda di invio? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }